@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'),
    url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Raleway-SemiBold';
  src: local('Raleway-SemiBold'),
    url(./assets/fonts/Raleway-SemiBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Raleway-Medium';
  src: local('Raleway-Medium'),
    url(./assets/fonts/Raleway-Medium.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'),
    url(./assets/fonts/Raleway-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Raleway-Light';
  src: local('Raleway-Light'),
    url(./assets/fonts/Raleway-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Montserrat-Black';
  src: local('Montserrat-Black'),
    url(./assets/fonts/Montserrat-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: local('Montserrat-ExtraBold'),
    url(./assets/fonts/Montserrat-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway-Italic';
  src: local('Raleway-Italic'),
    url(./assets/fonts/Raleway-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway-MediumItalic';
  src: local('Raleway-MediumItalic'),
    url(./assets/fonts/Raleway-MediumItalic.ttf) format('truetype');
}

$primary-color: #1490DD;
$text-color: #02224e;
$secondary-color: #99a2b3;
$white-color: #ffffff;
$black-color: #000000;
$body-color: #0c0f19;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-color;
  color: $white-color;
  overflow-wrap: anywhere;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading1 {
  font-family: 'Montserrat-Black';
  font-size: 48px;
}
.heading2 {
  font-family: 'Montserrat-ExtraBold';
  font-size: 36px;
}
.heading3 {
  font-family: 'Montserrat-Bold';
  font-size: 26px;
}
.heading4 {
  font-family: 'Montserrat-Black';
  font-size: 24px;
}
.heading5 {
  font-family: 'Montserrat-Bold';
  font-size: 18px;
}
.heading6 {
  font-family: 'Montserrat-Bold';
  font-size: 16px;
}

.textMedium20 {
  font-family: 'Raleway-Medium';
  font-size: 20px;
  margin: 0px;
}
.textItalic18 {
  font-family: 'Raleway-Italic';
  font-size: 18px;
  margin: 0px;
}
.textMediumItalic18 {
  font-family: 'Raleway-MediumItalic';
  font-size: 18px;
  margin: 0px;
}
.textMedium18 {
  font-family: 'Raleway-Medium';
  font-size: 18px;
  margin: 0px;
}
.textSemiBold18 {
  font-family: 'Raleway-SemiBold';
  font-size: 18px;
  margin: 0px;
}
.textMedium16 {
  font-family: 'Raleway-Medium';
  font-size: 16px;
  margin: 0px;
}
.textItalic16 {
  font-family: 'Raleway-Italic';
  font-size: 16px;
  margin: 0px;
}
.text16 {
  font-family: 'Raleway-Regular';
  font-size: 16px;
  margin: 0px;
}
.textBold14 {
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  margin: 0px;
}
.textMedium12 {
  font-family: 'Raleway-Medium';
  font-size: 12px;
  margin: 0px;
}
.textSemiBold12 {
  font-family: 'Raleway-SemiBold';
  font-size: 12px;
  margin: 0px;
}
.text14 {
  font-family: 'Raleway-Regular';
  font-size: 14px;
  margin: 0px;
}
.text12 {
  font-family: 'Raleway-Regular';
  font-size: 12px;
  margin: 0px;
}
.textMedium10 {
  font-family: 'Raleway-Medium';
  font-size: 10px;
  margin: 0px;
}

.text-color-primary {
  color: $primary-color;
}

.text-color-text {
    color: $text-color;
  }
.text-color-secondary {
  color: $secondary-color;
}

.textOnClick {
  color: $primary-color;
  &:hover {
    color: $secondary-color;
    cursor: pointer;
  }
}

.text-justify{
    text-align: justify;
}

ul > li{
    margin-top: 0.5rem 
}
ul{
    padding-left: 1rem;
}

.floatingLabel {
    position: fixed;
    padding: 1rem 3rem;
    border-radius: 0 0 2rem 2rem;
    background-color: white;
    top: -100px; /* Start off-screen */
    right: 2rem;
    transition: top 0.5s ease, opacity 0.3s ease; /* Transition on top and opacity */
  }
  
  .floatingLabelText {
    background: -webkit-linear-gradient(45deg,  #193b47, $primary-color,);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .scrolled {
    top: 0; /* Adjust the position when scrolled */
    opacity: 1; /* Adjust the opacity when scrolled */
  }


  

// .navbar-collapse {
//   // height: 100vh;
//   a {
//     font-family: 'Raleway-SemiBold';
//     font-size: 24px;
//     margin: 2rem 0px;
//   }
//   &.active {
//     font-family: 'Montserrat-Bold';
//     font-size: 24px;
//     border-bottom: 2px solid $primary-color;
//     color: $primary-color;
//   }
// }

.custom-btn {
  border-color: $primary-color;
  font-family: 'Raleway-Medium';
  background-color: transparent;
  color: $white-color;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all ease;

  &.cm-btn-lg {
    border-width: 4px;
    font-size: 18px;
    width: 200px;
    height: 60px;
  }

  &.cm-btn-sm {
    border-width: 2px;
    font-size: 14px;
    width: 140px;
    height: 30px;
  }
  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
.resendCodeBtn {
  color: $primary-color;
  padding-left: 0;
  padding-right: 0;
  &:hover {
    color: $secondary-color;
  }
  &:disabled {
    border: none;
  }
}

.CiAuthHeader {
  //   background-color: $black-color;
  //   position: absolute;
  height: 80px;
  width: 100%;
}
.CiHeader {
  background-color: $black-color;
  .navbarContainer{
    padding: 0 10rem;
  }
  .navbar-nav {
    display: flex;
    align-items: center;
    a {
      font-family: 'Montserrat-Bold';
      font-size: 14px;
      color: $white-color;
      margin-right: 2rem;
      transition-timing-function: ease;
      transition: 0.5s;
      text-decoration: none;
      &:hover {
        border-bottom: 2px solid $primary-color;
        font-size: 16px;
        transition-timing-function: ease;
        transition: 0.5s;
      }
      &.active {
        border-bottom: 2px solid $primary-color;
        font-size: 16px;
        color: $primary-color;
      }
    }
  }
  .headerLogo {
    width: 80px;
  }
  .nav-link-style-button {
    font-family: 'Montserrat-Bold';
    font-size: 14;
    color: $white-color !important;
    border: 2px solid $primary-color;
    padding: 8px 18px !important;
    transition-timing-function: ease;
    transition: 0.5s;
    &:hover {
      cursor: pointer;
      background-color: $primary-color;
      transition-timing-function: ease;
      transition: 0.5s;
    }
  }
  .navbar-toggler-icon {
    color: $white-color;
  }
}
.footer {
  background-color: $black-color;
  width: 100%;
//   padding: 50px 0px;
  padding: 50px 10rem;
  .content {
    .tagline {
      .tagline-para {
        width: 265px;
      }
    }
    .social-icons {
      a {
        margin-right: 1rem;
        color: $white-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
    .first-column {
      a {
        // margin-right: 1rem;
        text-decoration: none;
        color: $secondary-color;
        &:hover {
          text-decoration: underline !important;
          color: $primary-color !important;
        }
      }
      .links {
        display: flex;
        flex-direction: column;
        a {
          color: $white-color;
          margin-bottom: 0.25rem;
          text-decoration: none;
          &:hover {
            text-decoration: underline !important;
            color: $primary-color !important;
          }
        }
      }
      .phone-number {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 8px;
        }
        &:nth-child(3) {
          .details {
            margin-top: 13px;
          }
        }
        &:nth-child(4) {
            .details {
              margin-top: 13px;
            }
          }
      }
    }
  }
  .FooterImage {
    width: 60%;
  }
}

.Homeimage {
  width: 50%;
  padding: 2rem;
}

.about-2 {
  min-height: 330px;
  background-color: $black-color;
  .content {
    width: 100%;
  }
}

.about-3 {
  margin-top: 60px;
  margin-bottom: 60px;
  .content {
    width: 100%;
    margin: auto;
  }
}

.about-4 {
  margin: 60px 0px;
  .right-content {
    .image4 {
      width: 100%;
      object-fit: cover;
      margin: 10px 0px;
    }
  }
}

.about-5 {
  width: 100%;
  min-height: 650px;
//   background-image: url('./assets/images/AboutUs/AboutUs-sec5-image.png');

  .content {
    text-align: center;
    width: 80%;
    margin: auto;
  }
}

.LandingBanner {
  padding-right: 3rem;
  padding-left: 3rem;
  min-height: calc(100vh - 100px);
}

.DashboardBanner {
  padding-right: 2rem;
  padding-left: 2rem;
  min-height: calc(100vh - 80px);
}

.backgroundGradient{
  background-image: url('./assets/images/main-bg-grad.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.backgroundAboutUs{
    background:linear-gradient(45deg, rgba(0, 0, 0, .5) 0%, rgba(21,113,232, 0.5) 100%), url(./assets/images/Services.jpeg);

    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
 
  }

.backgroundOurWay{
    background:linear-gradient(45deg, rgba(0, 0, 0, .5) 0%, rgba(21,113,232, 0.5) 100%), url(./assets/images/ourWay-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
  }
.backgroundSecurity{
    background:linear-gradient(45deg, rgba(0, 0, 0, .5) 0%, rgba(21,113,232, 0.5) 100%), url(./assets/images/security-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;

  }

.backgroundContactUs{
    background:linear-gradient(45deg, rgba(0, 0, 0, .5) 0%, rgba(21,113,232, 0.5) 100%), url(./assets/images/contact-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
  }

.backgroundServices{
    background:linear-gradient(45deg, rgba(0, 0, 0, .5) 0%, rgba(21,113,232, 0.5) 100%), url(./assets/images/services-banner.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
  }

.homeContainer {
  background-image: url('./assets/images/Home/HomeBanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .homeOverlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #00000075;
  }
  .heading1{
  font-size: 65px;
  }
  .homeBannerImage {
    mix-blend-mode: screen;
    // height: 550px;
    width: 100%;
    object-fit: contain;
  }
}

.overlayBlackGradientRL {
  min-height: 540px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 212, 255, 0) 100%
  );
}
.overlayBlackGradientLR {
  min-height: 540px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 212, 255, 0) 100%
  );
}
.section2GradientLR {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    125deg,
    rgba(0, 0, 0, 1) 50%,
    rgba(46, 218, 241, 1) 100%
  );
  overflow: hidden;
}

.section2GradientRL {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    245deg,
    rgba(0, 0, 0, 1) 50%,
    rgba(46, 218, 241, 1) 100%
  );
}
.homeSec-1 {
  .sec1Image {
    min-height: 600px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .details {
  }
}

.qri-2 {
  .qriSec1Image {
    object-fit: cover;
    border: 4px solid $primary-color;
    border-radius: 0.5rem;
    height: 600px;
    width: 80%;
  }
}

.qri-3 {
  background-color: $primary-color;
  padding: 1rem 0;
  text-align: center;
}
.qri-4 {
  .sec4Row {
    margin: 3rem 0px;
  }
  .image {
    object-fit: contain;
    // height: 300px;
    width: 100%;
  }
  .details {
    width: 70%;
  }
}

// Application Component CSS
.application-2 {
  margin: 3rem 0px;
  .AppColumns {
    position: relative;
    .ApplicationCard {
      margin: 3rem 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .image {
        object-fit: contain;
        width: 100%;
      }
      .details {
        // text-align: center;
        border: 4px solid $primary-color;
        border-radius: 1rem;
        padding: 2rem;
        margin: 0px 4rem;
        margin-top: -2rem;
        // position: absolute;
        background-color: $black-color;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        // top: 19rem;
        h5 {
          text-align: center;
        }
        p {
          text-align: justify;
        }
      }
    }
  }
}
.authScreenSection {
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  .authImage {
    width: 100%;
    height: 800px;
    // height: calc(100vh - 100px);
    object-fit: cover;
    border-radius: 1rem;
  }
  .LoginContainer {
    input {
      background-color: transparent;
      border: 1px solid $primary-color;
      border-radius: 0.5rem;
      // height: 60px;
      padding: 1rem;
      color: $white-color;
      font-family: 'Raleway-Semibold';
    }
    .divider {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      hr {
        width: 80%;
        text-align: center;
      }
      p {
        position: absolute;
        padding: 0.5rem;
        border: 1px solid $secondary-color;
        border-radius: 50px;
        background-color: $body-color;
      }
    }
    .authButton {
      width: 60%;
      height: 50px;
    }
    a {
      color: $primary-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        // color: $white-color;
      }
    }
  }
}

.contactus-2 {
  margin: 3rem 0px;

  .contact-tabs {
    margin-top: 1rem;
    .tab-1 {
      background-color: $black-color;
      padding: 2rem 2rem;
      border-radius: 1rem;
      margin-bottom: 2rem;
      .icon {
        margin-right: 3rem;
      }
      .content {
      }
      a {
        color: $secondary-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  .contact-form {
    .tab-1 {
      background-color: $black-color;
      height: 100%;
      // padding: 3rem 3rem;
      border-radius: 1rem;
    }
    input,
    textarea {
      background-color: transparent;
      border: 1px solid $primary-color;
      border-radius: 0.5rem;
      // height: 60px;
      padding: 1rem;
      color: $white-color;

      font-family: 'Raleway-Semibold';
    }

    .submitButton {
      // width: 60%;
      height: 50px;
    }
    a {
      color: $primary-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        // color: $white-color;
      }
    }
  }
}

.blogs-2 {
  margin: 3rem 0px;

  .blogsCard {
    background-color: $black-color;
    // border: 4px solid $primary-color;
    border-radius: 1rem;

    .card-body {
      // padding-left: 2rem;
      // padding-right: 2rem;

      .readMoreText {
        color: $primary-color;
        cursor: pointer;
        font-family: 'Montserrat-Bold';
        font-size: 14px;
        margin: 0px;
      }
      :first-child.expanded {
        overflow: visible; /* Allow content to overflow its container */
        height: auto; /* Let the height adjust dynamically based on content */
        // transition: height 1s;
      }
      :first-child:not(.expanded) {
        overflow: hidden;
        height: 3rem; /* Adjust the height as needed */
        transition: height 0.3s ease;
      }
    }
  }
}

.detailsss {
  text-align: center;
  border: 4px solid $primary-color;
  border-radius: 1rem;
  padding: 2rem;
  // margin-top: -2rem;
  background-color: #000000;
  min-height: 100%;
  width: 70%;
  margin: auto;
}

.hello {
  margin-top: -2rem;
}
.suspenseLoader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
    width: 64px;
    height: 64px;
    position: relative;
    animation: rotate 1.5s ease-in infinite alternate;
  }
  .loader::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    color: $primary-color;
    background: currentColor;
    width: 64px;
    height: 32px;
    border-radius: 0 0 50px 50px;
  }
  .loader::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 10%;
    background: #FFF;
    width: 8px;
    height: 64px;
    animation: rotate 1.2s linear infinite alternate-reverse;
  }
  
  @keyframes rotate {
    100% { transform: rotate(360deg)}
  }
        

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $black-color $secondary-color;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: $secondary-color;
}

*::-webkit-scrollbar-track:hover {
  background-color: $secondary-color;
}

*::-webkit-scrollbar-track:active {
  background-color: $secondary-color;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: $black-color;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #2acbe1;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #28bcd0;
}

.navbarMobile {
  .header {
    height: 100px;
    padding: 0px 1rem;
    align-items: center;
    background-color: $black-color;
    display: flex;
    justify-content: space-between;
    z-index: 9999;

    #nav-icon3 {
      span {
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          top: 18px;
        }
        &:nth-child(3) {
          top: 18px;
        }
        &:nth-child(4) {
          top: 36px;
        }
      }
    }
    #nav-icon3.open {
      span {
        &:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
  .customMenu {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: $black-color;
    top: 100px;
    // left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: center;
    a {
      color: $white-color;
      padding: 1rem 0px;
      font-family: 'Montserrat-Bold';
      text-decoration: none;
      font-size: 20px;
      &.active {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
}

.tooltip {
  .tooltip-inner {
    background-color: #ffffff;
    color: #000000;
  }
  .tooltip-arrow {
    &:before {
      border-left-color: #ffffff;
    }
  }
}

#notfound {
  position: relative;
  height: 100vh;
  background-color: #222;
  .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
  .notfound-404 {
    // height: 158px;
    line-height: 153px;
    h1 {
      // font-family: 'Josefin Sans', sans-serif;
      color: #222;
      font-size: 220px;
      letter-spacing: 10px;
      margin: 0px;
      font-weight: 700;
      text-shadow: 2px 2px 0px $secondary-color, -2px -2px 0px $secondary-color;
      > span {
        text-shadow: 2px 2px 0px $primary-color, -2px -2px 0px $primary-color,
          0px 0px 8px #57e1f4;
      }
    }
  }
  p {
    // font-family: 'Josefin Sans', sans-serif;
    color: $secondary-color;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  a {
    // font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: transparent;
    color: $secondary-color;
    border: 2px solid $secondary-color;
    display: inline-block;
    padding: 10px 25px;
    font-weight: 700;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    &:hover {
      color: $primary-color;
      border-color: $primary-color;
    }
  }
}
@media only screen and (max-width: 480px) {
  .notfound {
    .notfound-404 {
      height: 122px;
      line-height: 122px;
      h1 {
        font-size: 122px;
      }
    }
  }
} 

@media (max-width: 767px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .heading1 {
    font-size: 36px;
  }
  .heading2 {
    font-size: 28px;
  }
  .heading3 {
    font-size: 24px;
  }
  .heading4 {
    font-size: 20px;
  }
  .heading5 {
    font-size: 18px;
  }
  .heading6 {
    font-size: 16px;
  }

  .textMedium20 {
    font-size: 18px;
  }
  .textItalic18 {
    font-size: 16px;
  }
  .textMediumItalic18 {
    font-size: 16px;
  }
  .textMedium18 {
    font-size: 16px;
  }
  .textSemiBold18 {
    font-size: 16px;
  }
  .textMedium16 {
    font-size: 14px;
  }
  .textItalic16 {
    font-size: 14px;
  }
  .text16 {
    font-size: 14px;
  }
  .textBold14 {
    font-size: 12px;
  }
  .textMedium12 {
    font-size: 10px;
  }
  .textSemiBold12 {
    font-size: 10px;
  }
  .text12 {
    font-size: 10px;
  }
  .textMedium10 {
    font-size: 8px;
  }

  .Homeimage {
    width: 100%;
    padding: 2rem;
  }

  .application-2 {
    .AppColumns {
      .ApplicationCard {
        .details {
          margin: 0px 2rem;
          margin-top: -2rem;
        }
      }
    }
  }
  .footer{
  padding: 50px 1rem;

  }
}

@media (max-width: 1024px) {
  .authScreenSection {
    .authImage {
      width: 100%;
      height: 500px;
      object-fit: cover;
      // height: calc(100vh - 100px);
    }
  }
  .homeContainer{
    .heading1{
        font-size: 40px;
    }
  }
  .CiHeader {
    .navbarContainer{
        padding: 0 3rem;
      }
    .navbar-nav {
      a {
        font-size: 10px;
        margin-right: 0.5rem;
        &:hover {
          font-size: 12px;
        }
        &.active {
          font-size: 12px;
        }
      }
    }
    .headerLogo {
      width: 70px;
    }
    .nav-link-style-button {
      font-size: 11px;
      padding: 8px 18px !important;
    }
  }
}
